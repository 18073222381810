import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const LandingPage = () => {

    const [slogans, setSlogans] = useState([]);
    const [atGlances, setAtGlances] = useState([]);
    const [formData, setFormData] = useState({ slogan: '', slider_image: null });
    const [formAtAGlanceData, setAtAGlanceFormData] = useState({
        at_glan_title: '',
        at_glan_short_desc: '',
        at_glan_long_desc: ''
    });
    const [activeSection, setActiveSection] = useState('SloganSection');
    const [image, setImage] = useState(null);
    const [allImage, setAllImage] = useState([]);

    // const [formClientLogoData, setClientLogoFormData] = useState({client_logo: ''});
    const [client_logo, setClientLogo] = useState(null);
    const [allClientLogo, setAllClientLogo] = useState([]);

    // Calling function object code starts
    useEffect(() => {
        fetchSlogans();
        fetchAtGlances();
        getSliderImage();
        getClientLogo();
    }, []);
    // Calling function object code ends





   // Client Logo CRUD starts
    const getClientLogo = async () => {
        try {
            const response = await axios.get("https://serverend.fancygroupltd.com/sys/api/get-client-logo");
            setAllClientLogo(response.data);
        } catch (error) {
            console.error("Error fetching image data:", error);
        }
    };

    const onClientLogoInputChange = (e) => {
    console.log(e.target.files[0]);
    setClientLogo(e.target.files[0]);
    };

    const submitClientLogo = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", client_logo);

    try {
        const result = await axios.post(
            "https://serverend.fancygroupltd.com/sys/api/upload-client-logo", formData,
            {
            headers: { "Content-Type": "multipart/form-data" },
            }
        );
        getClientLogo();
        toast.success(result.data.message);
        } catch (error) {
            console.error('Error: ', error);
            toast.error(error.response.data.message); 
        }

    };
    const handleClientLogoDelete = async id => {
        try {
            await axios.delete(`https://serverend.fancygroupltd.com/sys/api/delete-client-logo/${id}`);
            getClientLogo();
            toast.success('Deleted!');
        } catch (error) {
            console.error('Error deleting: ', error);
        }
    };
    // Client Logo CRUD ends











    // Slider Images CRUD starts
    const getSliderImage = async () => {
        try {
            const response = await axios.get("https://serverend.fancygroupltd.com/sys/api/get-slider-image");
            setAllImage(response.data);
        } catch (error) {
            console.error("Error fetching image data:", error);
        }
      };

    const onInputChange = (e) => {
    console.log(e.target.files[0]);
    setImage(e.target.files[0]);
    };

    const submitImage = async (e) => {
      e.preventDefault();
  
      const formData = new FormData();
      formData.append("image", image);

      try {
        const result = await axios.post(
            "https://serverend.fancygroupltd.com/sys/api/upload-slider-image", formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          getSliderImage();
          toast.success(result.data.message);
        } catch (error) {
            console.error('Error: ', error);
            toast.error(error.response.data.message); 
        }

    };
    const handleSliderImageDelete = async id => {
        try {
            await axios.delete(`https://serverend.fancygroupltd.com/sys/api/delete-slider-image/${id}`);
            getSliderImage();
            toast.success('Deleted!');
        } catch (error) {
            console.error('Error deleting: ', error);
        }
    };
    // Slider Images CRUD ends

  










    //SLOGAN CRUD STARTS
    const fetchSlogans = async () => {
        try {
            const response = await axios.get('https://serverend.fancygroupltd.com/sys/api/slogans');
            setSlogans(response.data);
        } catch (error) {
            console.error('Error fetching slogans: ', error);
        }
    };

    const handleSloganChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSloganSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post('https://serverend.fancygroupltd.com/sys/api/slogans', formData);
            fetchSlogans();
            setFormData({ ...formData, slogan: '' });
            toast.success(response.data.message);
        } catch (error) {
            console.error('Error creating slogan: ', error);
        }
    };

    const handleSloganDelete = async id => {
        try {
            const response = await axios.delete(`https://serverend.fancygroupltd.com/sys/api/slogans/${id}`);
            fetchSlogans();
            toast.success(response.data.message);
        } catch (error) {
            console.error('Error deleting slogan: ', error);
        }
    };
    // SLOGAN CRUD ENDS

    //AT A GLANCE CRUD STARTS
    const fetchAtGlances = async () => {
        try {
            const response = await axios.get('https://serverend.fancygroupltd.com/sys/api/at-glances');
            setAtGlances(response.data);
        } catch (error) {
            console.error('Error fetching at-glances: ', error);
        }
    };

    const handleAtAGlanceChange = e => {
        const { name, value } = e.target;
        setAtAGlanceFormData({ ...formAtAGlanceData, [name]: value });
    };

    const handleAtAGlanceSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post('https://serverend.fancygroupltd.com/sys/api/at-glances', formAtAGlanceData);
            fetchAtGlances(); // Make sure fetchAtGlances is defined and does what you expect
            setAtAGlanceFormData({
                at_glan_title: '',
                at_glan_short_desc: '',
                at_glan_long_desc: ''
            });
            toast.success(response.data.message);
        } catch (error) {
            console.error('Error creating: ', error);
        }
    };

    const handleAtGlanceDelete = async id => {
        try {
            const response = await axios.delete(`https://serverend.fancygroupltd.com/sys/api/at-glances/${id}`);
            fetchAtGlances();
            toast.success(response.data.message);
        } catch (error) {
            console.error('Error deleting slogan: ', error);
        }
    };
    // AT A GLANCE CRUD ENDS

    const handleSectionButtonClick = sectionId => {
        setActiveSection(sectionId);
    };

    return (
        <div>

            <>
            <div>
                <button className="btn btn-warning m-2" onClick={() => handleSectionButtonClick('SliderImagesSection')}>Slider Images</button>
                <button className="btn btn-info m-2" onClick={() => handleSectionButtonClick('SloganSection')}>Slogan</button>
                <button className="btn btn-success m-2" onClick={() => handleSectionButtonClick('ClientLogoSection')}>Client Logo</button>
                <button className="btn btn-danger m-2" onClick={() => handleSectionButtonClick('AtAGlanceSection')}>At A Glance</button>
                {/* <button className="btn btn-dark m-2" onClick={() => handleSectionButtonClick('ExploreMoreProductsSection')}>Explore More Products</button> */}
            </div>

            {activeSection === 'SliderImagesSection' && (
                // Render Slider Images section content here
                <div>
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">

                    <div className="col-sm-6">
                        <h1>Slider Image Details - <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#SliderImagesModal">Add Slider Image</button></h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                        <li className="breadcrumb-item active">Landing Page</li>
                        </ol>
                    </div>
                

                        {/* <!-- Modal --> */}
                        <div className="modal fade" id="SliderImagesModal" tabindex="-1" role="dialog" aria-labelledby="SliderImagesModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form onSubmit={submitImage} className="m-5">
                                    <input type="file" accept="image/*" onChange={onInputChange}></input>
                                    <br/>
                                    <br/>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </form>
                            </div>
                        </div>
                        </div>
                        {/* <!-- End Modal --> */}


                    </div>
                </div>{/* /.container-fluid */}
                </section>

                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12">

                        {/* /.card */}

                        <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Slider Image Details</h3>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                            <table id="example1" className="table table-bordered table-striped text-center">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allImage.map(data => (
                                    <tr key={data.id}>
                                        <td><img src={`https://serverend.fancygroupltd.com/sliderimages/${data.image}`}  alt="Image" style={{ width: '150px', height: '150px'}} /></td>
                                        <td>
                                            <button onClick={() => handleSliderImageDelete(data.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            </table>
                        </div>
                        {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                    {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
                </section>
            </div>
            )}
            {activeSection === 'SloganSection' && (
                // Render Slogan section content here
                <div>
                    <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">

                        <div className="col-sm-6">
                            <h1>Slogan Details - <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Add Slogan</button></h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                            <li className="breadcrumb-item active">Landing Page</li>
                            </ol>
                        </div>
                    

                            {/* <!-- Modal --> */}
                            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            

                                <form onSubmit={handleSloganSubmit}>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label for="recipient-name" className="col-form-label">Slogan Name:</label>
                                            <input type="text" className="form-control" name="slogan" value={formData.slogan} placeholder="Slogan" onChange={handleSloganChange} required />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="submit" className="btn btn-success">Add Slogan</button>
                                    </div>
                                </form>

                                </div>
                            </div>
                            </div>
                            {/* <!-- End Modal --> */}


                        </div>
                    </div>{/* /.container-fluid */}
                    </section>

                    {/* Main content */}
                    <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-12">

                            {/* /.card */}

                            <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Slogan Details</h3>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body">
                                <table id="example1" className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Slogan</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slogans.map(slogan => (
                                        <tr key={slogan.id}>
                                            <td>{slogan.slogan}</td>
                                            <td>
                                                <button>Edit</button>
                                                <button onClick={() => handleSloganDelete(slogan.id)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Slogan</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                                </table>
                            </div>
                            {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                    </section>
                </div>
            )}
            {activeSection === 'ClientLogoSection' && (
                // Render Client Logo section content here
                <div>
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">

                    <div className="col-sm-6">
                        <h1>Client Logo Details - <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#ClientLogoModal">Add Client Logo</button></h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                        <li className="breadcrumb-item active">Landing Page</li>
                        </ol>
                    </div>
                

                        {/* <!-- Modal --> */}
                        <div className="modal fade" id="ClientLogoModal" tabindex="-1" role="dialog" aria-labelledby="ClientLogoModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form onSubmit={submitClientLogo} className="m-5">
                                    <input type="file" accept="image/*" onChange={onClientLogoInputChange}></input>
                                    <br/>
                                    <br/>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </form>
                            </div>
                        </div>
                        </div>
                        {/* <!-- End Modal --> */}


                    </div>
                </div>{/* /.container-fluid */}
                </section>

                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12">

                        {/* /.card */}

                        <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Client Logo Details</h3>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                            <table id="example1" className="table table-bordered table-striped text-center">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allClientLogo.map(allClientLogo_value => (
                                    <tr key={allClientLogo_value.id}>
                                        <td><img src={`https://serverend.fancygroupltd.com/clientLogos/${allClientLogo_value.client_logo}`}  alt="Image" style={{ width: '150px', height: '150px'}} /></td>
                                        <td>
                                            <button onClick={() => handleClientLogoDelete(allClientLogo_value.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            </table>
                        </div>
                        {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                    {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
                </section>
            </div>
            )}
            {activeSection === 'AtAGlanceSection' && (
                // Render At A Glance section content here
                <div>
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">

                    <div className="col-sm-6">
                        <h1>At A Glance Details - <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Add At A Glance</button></h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                        <li className="breadcrumb-item active">Landing Page</li>
                        </ol>
                    </div>
                

                        {/* <!-- Modal --> */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        

                            <form onSubmit={handleAtAGlanceSubmit}>
                                <div className="modal-body">
                                    
                                    <div className="form-group">
                                        <label for="recipient-name" className="col-form-label">Title:</label>
                                        <input type="text" className="form-control" name="at_glan_title" placeholder="Title" onChange={handleAtAGlanceChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label for="recipient-name" className="col-form-label">Short Description:</label>
                                        <input type="text" className="form-control" name="at_glan_short_desc" placeholder="Short Description" onChange={handleAtAGlanceChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label for="recipient-name" className="col-form-label">Long Description:</label>
                                        <input type="text" className="form-control" name="at_glan_long_desc" placeholder="Long Description" onChange={handleAtAGlanceChange} required />
                                    </div>

                                    
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-success">Add At A Glance</button>
                                </div>
                            </form>

                            </div>
                        </div>
                        </div>
                        {/* <!-- End Modal --> */}


                    </div>
                </div>{/* /.container-fluid */}
                </section>

                {/* Main content */}
                <section className="content">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12">

                        {/* /.card */}

                        <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">At A Glance Details</h3>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                            <table id="example1" className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Short</th>
                                    <th>Long Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {atGlances.map(atGlance => (
                                    <tr key={atGlance.id}>
                                        <td>{atGlance.at_glan_title}</td>
                                        <td>{atGlance.at_glan_short_desc}</td>
                                        <td>{atGlance.at_glan_long_desc}</td>
                                        <td>
                                            <button>Edit</button>
                                            <button onClick={() => handleAtGlanceDelete(atGlance.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Title</th>
                                    <th>Short</th>
                                    <th>Long Description</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            </table>
                        </div>
                        {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                    {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
                </section>
            </div>
            )}
            {activeSection === 'ExploreMoreProductsSection' && (
                // Render Explore More Products section content here
                <div>Explore More Products Section</div>
            )}


        </>









        </div>
    );
};

export default LandingPage;
