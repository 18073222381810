import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from './adminPanelLayouts/MainLayout';
import Dashboard from './adminPanelPages/Dashboard';
import CompanyDetails from './adminPanelPages/CompanyDetails';
import LandingPage from './adminPanelPages/LandingPage';
import GroupPage from './adminPanelPages/GroupPage';
import BrandsPage from './adminPanelPages/BrandsPage';
import ServicePage from './adminPanelPages/ServicePage';
import TeamPage from './adminPanelPages/TeamPage';
import ContactPage from './adminPanelPages/ContactPage';
import ProductPage from './adminPanelPages/ProductPage';
import NewsFeedPage from './adminPanelPages/NewsFeedPage';

function App() {
  return (
    <Router> {/* Wrap your application with Router */}
      <div className="App">
        <MainLayout>
          <Routes>
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/company-details" element={<CompanyDetails />} />
            <Route path="/admin/landing-page" element={<LandingPage />} />
            <Route path="/admin/group" element={<GroupPage />} />
            <Route path="/admin/brands" element={<BrandsPage />} />
            <Route path="/admin/services" element={<ServicePage />} />
            <Route path="/admin/team" element={<TeamPage />} />
            <Route path="/admin/contact" element={<ContactPage />} />
            <Route path="/admin/products" element={<ProductPage />} />
            <Route path="/admin/newsfeed" element={<NewsFeedPage />} />
          </Routes>
          <ToastContainer />
        </MainLayout>
      </div>
    </Router>
  );
}

export default App;
