import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PullToRefresh from 'react-pull-to-refresh';
const CompanyDetails = () => {

    const [company_details, setDatas] = useState([]);
    const [formData, setFormData] = useState({ c_name: '', c_email: '', c_phone: '', c_address: '', c_logo: '' });
    const [loading, setLoading] = useState(true);
    const [c_logo, setImage] = useState(null);

    useEffect(() => {
      fetchDatas();
    }, []);

    const fetchDatas = async () => {

      setLoading(true);
      try {
        const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/company-details');
        setDatas(response.data);
      } catch (error) {
        console.error('Error fetching company details: ', error);
      } finally {
        setLoading(false);
      }

    };

    const handleChange = e => {
      const { name, value } = e.target;
      if (name === 'c_logo') {
        setImage(e.target.files[0]); // Update the file state
      } else {
        setFormData({ ...formData, [name]: value }); // Update other form fields
      }
    };

    const handleSubmit = async e => {
      e.preventDefault();
      const formNewData = new FormData();
      formNewData.append("c_logo", c_logo); // Append logo from state
      formNewData.append("c_name", formData.c_name);
      formNewData.append("c_email", formData.c_email);
      formNewData.append("c_phone", formData.c_phone);
      formNewData.append("c_address", formData.c_address);
      try {
        const response = await axios.post('https://fancyltdserver.fancybeauty.com.bd/sys/api/company-details', formNewData);
        fetchDatas();
        setFormData({ c_name: '', c_email: '', c_phone: '', c_address: '', c_logo: '' });
        console.log(response.data.message);
        toast.success(response.data.message);
      } catch (error) {
        console.error('Error creating or updating company details: ', error);
        toast.error('Error creating or updating company details. Please try again.');
      }
    };

    const handleDelete = async id => {
      try {
        await axios.delete(`https://fancyltdserver.fancybeauty.com.bd/sys/api/company-details/${id}`);
        fetchDatas();
        toast.success('Deleted successfully');
      } catch (error) {
        console.error('Error deleting user: ', error);
      }
    };



  
  return (
 

    <div>


            <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">

              <div className="col-sm-6">
                <h1>Company Details - <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Add Customer Details</button></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item active">Brands</li>
                </ol>
              </div>



            {/* <!-- Button trigger modal --> */}
           

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    
                      <div className="form-group">
                        <label for="recipient-name" className="col-form-label">Company Name:</label>
                        <input type="text" className="form-control" name="c_name" value={formData.c_name} placeholder="Company Name" onChange={handleChange} required />
                      </div>
                      <div className="form-group">
                        <label for="recipient-name" className="col-form-label">Company Email:</label>
                        <input type="email" className="form-control" name="c_email" value={formData.c_email} placeholder="Company Email" onChange={handleChange} required />
                      </div>
                      <div className="form-group">
                        <label for="recipient-name" className="col-form-label">Company Phone:</label>
                        <input type="tel" className="form-control" name="c_phone" value={formData.c_phone} placeholder="Company Phone" onChange={handleChange} required />
                      </div>
                      <div className="form-group">
                        <label for="recipient-name" className="col-form-label">Company Address:</label>
                        <input type="text" className="form-control" name="c_address" value={formData.c_address} placeholder="Company Address" onChange={handleChange} required />
                      </div>
                      <div className="form-group">
                        <label for="recipient-name" className="col-form-label">Company Logo:</label>
                        <input type="file" name="c_logo" className="form-control" accept="image/*"  onChange={handleChange} required />
                      </div>
                    
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-success">Add Company Details</button>
                  </div>
                  </form>
                </div>
              </div>
            </div>






            </div>
          </div>{/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

                {/* /.card */}

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Company Details</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table id="example1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Company Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Address</th>
                          <th>Logo</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {company_details.map(company_detail => (
                        <tr key={company_detail.id}>
                          <td>{company_detail.c_name}</td>
                          <td>{company_detail.c_email}</td>
                          <td>{company_detail.c_phone}</td>
                          <td>{company_detail.c_address}</td>
                          <td><img src={`https://fancyltdserver.fancybeauty.com.bd/companyLogos/${company_detail.c_logo}`}  alt="Image" style={{ width: '150px', height: '150px'}} /></td>
                          <td>
                            <button className="btn btn-success m-2">Edit</button>
                            <button className="btn btn-danger m-2" onClick={() => handleDelete(company_detail.id)}>Delete</button>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Company Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Address</th>
                          <th>Logo</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>


    </div>

  );
};

export default CompanyDetails;