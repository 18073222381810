import React from 'react';

const ContactPage = () => {
  return (
    <div>
          <h1>ContactPage</h1>
    </div>
  );
};

export default ContactPage;