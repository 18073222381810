import React from 'react';
import { Link } from 'react-router-dom';
const MainLayout = ({ children }) => {
  return (
    <div>
        

        <body class="hold-transition sidebar-mini layout-fixed">
          <div class="wrapper">

            {/* <!-- Preloader --> */}
            <div class="preloader flex-column justify-content-center align-items-center">
              <img class="animation__shake" src="assets/dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
            </div>

            {/* <!-- Navbar --> */}
            <nav class="main-header navbar navbar-expand navbar-white navbar-light">


              {/* <!-- Right navbar links --> */}
              <ul class="navbar-nav ml-auto">
                {/* <!-- Navbar Search --> */}
                <li class="nav-item">
                  <a class="nav-link" data-widget="navbar-search" href="#" role="button">
                    <i class="fas fa-search"></i>
                  </a>
                  <div class="navbar-search-block">
                    <form class="form-inline">
                      <div class="input-group input-group-sm">
                        <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                        <div class="input-group-append">
                          <button class="btn btn-navbar" type="submit">
                            <i class="fas fa-search"></i>
                          </button>
                          <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                            <i class="fas fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>

                {/* <!-- Messages Dropdown Menu --> */}
                <li class="nav-item dropdown">
                  <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="far fa-comments"></i>
                    <span class="badge badge-danger navbar-badge">3</span>
                  </a>
                </li>
                {/* <!-- Notifications Dropdown Menu --> */}
                <li class="nav-item dropdown">
                  <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="far fa-bell"></i>
                    <span class="badge badge-warning navbar-badge">15</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i class="fas fa-expand-arrows-alt"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                    <i class="fas fa-th-large"></i>
                  </a>
                </li>
              </ul>
            </nav>
            {/* <!-- /.navbar --> */}

            {/* <!-- Main Sidebar Container --> */}
            <aside class="main-sidebar sidebar-dark-primary elevation-4">
              {/* <!-- Brand Logo --> */}
              <a href="index3.html" class="brand-link">
                <img src="assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style={{ opacity: 0.8 }} />
                <span class="brand-text font-weight-light">AdminLTE 3</span>
              </a>

              {/* <!-- Sidebar --> */}
              <div class="sidebar">
                {/* <!-- Sidebar user panel (optional) --> */}
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div class="image">
                    <img src="assets/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" />
                  </div>
                  <div class="info">
                    <a href="#" class="d-block">Alexander Pierce</a>
                  </div>
                </div>

                {/* <!-- SidebarSearch Form --> */}
                <div class="form-inline">
                  <div class="input-group" data-widget="sidebar-search">
                    <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                    <div class="input-group-append">
                      <button class="btn btn-sidebar">
                        <i class="fas fa-search fa-fw"></i>
                      </button>
                    </div>
                  </div>
                </div>

                {/* <!-- Sidebar Menu --> */}
                <nav class="mt-2">
                  <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    {/* <!-- Add icons to the links using the .nav-icon class
                        with font-awesome or any other icon font library --> */}

                    <li class="nav-item">
                        <Link to="/admin/dashboard" className="nav-link">
                        <i class="nav-icon fas fa-th"></i>
                        <p>
                          Dashboard
                        </p>
                        </Link>
                    </li>
                    <li class="nav-item">
                        <Link to="admin/company-details" className="nav-link">
                        <i class="nav-icon fas fa-th"></i>
                        <p>Company Details</p>
                        </Link>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="nav-icon fas fa-book"></i>
                        <p>
                          Pages
                          <i class="fas fa-angle-left right"></i>
                        </p>
                      </a>
                      <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <Link to="admin/landing-page" className="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Landing Page</p>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="admin/group" className="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Group</p>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="admin/brands" className="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Brands</p>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="admin/products" className="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Products</p>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="admin/services" className="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Services</p>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="admin/newsfeed" className="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>News Feed</p>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="admin/team" className="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Team</p>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="admin/contact" className="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Contact</p>
                            </Link>
                        </li>
                      </ul>
                    </li>



                  </ul>
                </nav>
                {/* <!-- /.sidebar-menu --> */}
              </div>
              {/* <!-- /.sidebar --> */}
            </aside>

            {/* <!-- Content Wrapper. Contains page content --> */}
            <div class="content-wrapper">
              

           
              <div>{ children }</div>


            </div>
            
            

            {/* <!-- /.content-wrapper --> */}
            <footer class="main-footer">
              <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong>
              All rights reserved.
              <div class="float-right d-none d-sm-inline-block">
                <b>Version</b> 3.2.0
              </div>
            </footer>

            {/* <!-- Control Sidebar --> */}
            <aside class="control-sidebar control-sidebar-dark">
              {/* <!-- Control sidebar content goes here --> */}
            </aside>
            {/* <!-- /.control-sidebar --> */}
          </div>
          {/* <!-- ./wrapper --> */}


          </body>





        
    </div>
  );
};

export default MainLayout;