




import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import PullToRefresh from 'react-pull-to-refresh';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ProductPage = () => {

  const [brands, setBrandDatas] = useState([]);
  const [products, setDatas] = useState([]);
  const [formData, setFormData] = useState({ product_brand_id: '', product_cat_name: '', product_title: '', product_short_desc: '', product_long_desc: '', product_image:'' });
  const [product_image, setProductImage] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    fetchDatas();
    fetchBrandDatas();
  }, []);

  const fetchBrandDatas = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/brand');
      setBrandDatas(response.data);
    } catch (error) {
      console.error('Error fetching: ', error);
    } finally {
      setLoading(false);
    }

  };
  const fetchDatas = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/product');
      setDatas(response.data);
    } catch (error) {
      console.error('Error fetching: ', error);
    } finally {
      setLoading(false);
    }

  };

  const handleChange = e => {
    if (e.target.name === 'product_image') {
        setProductImage(e.target.files[0]); // Update the file state
    } else {
      // Update the formData state for non-file inputs
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
  
    const formNewData = new FormData();
    formNewData.append("product_image", product_image); // Append logo from state
    formNewData.append("product_brand_id", formData.product_brand_id);
    formNewData.append("product_cat_name", formData.product_cat_name);
    formNewData.append("product_title", formData.product_title);
    formNewData.append("product_short_desc", formData.product_short_desc);
    formNewData.append("product_long_desc", formData.product_long_desc);
    formNewData.append("product_source_link", formData.product_source_link);
  
     console.log(formNewData);
  
    try {
      const response = await axios.post('https://fancyltdserver.fancybeauty.com.bd/sys/api/product', formNewData);
      fetchDatas();
      setFormData({ product_brand_id: '',product_cat_name: '', product_title: '', product_short_desc: '', product_long_desc: '', product_image: '', product_source_link: '' });
      console.log(response.data.message);
      toast.success(response.data.message);
    } catch (error) {
      console.error('Error creating or updating: ', error);
      toast.error('Error creating or updating. Please try again.');
    }
  };
  

  const handleDelete = async id => {
    try {
      await axios.delete(`https://fancyltdserver.fancybeauty.com.bd/sys/api/product/${id}`);
      fetchDatas();
      toast.success('Deleted successfully');
    } catch (error) {
      console.error('Error deleting: ', error);
    }
  };


  const refreshHandler = async () => {
    await fetchDatas();
    return true; // Return true to indicate that the refresh has completed
  };

  return (
    <div>
        <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">

              <div className="col-sm-6">
                <h1>Product Details - <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Add Product</button></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item active">Product Details</li>
                </ol>
              </div>
           

              {/* <!-- Modal --> */}
              <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Add</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                          <div className="form-group">
                            <label htmlFor="product_title" className="col-form-label">Brand Name:</label>
                            <select className="form-control" name="product_brand_id" value={formData.product_brand_id} onChange={handleChange} required>
                            <option value="">Open this select menu</option>
                                {brands.map(brand => (
                                    <option key={brand.id} value={brand.id}>{brand.brand_title}</option>
                                ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="product_title" className="col-form-label">Category:</label>
                            <select className="form-control" name="product_cat_name" value={formData.product_cat_name} onChange={handleChange} required>
                              <option>Open this select menu</option>
                              <option value="Cosmetics">Cosmetics</option>
                              <option value="Furnitures">Furnitures</option>
                              <option value="Machineries">Machineries</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="product_title" className="col-form-label">Title:</label>
                            <input type="text" className="form-control" name="product_title" id="product_title" placeholder="Brand Name" value={formData.product_title} onChange={handleChange} required />
                          </div>
                          <div className="form-group">
                            <label htmlFor="product_image" className="col-form-label">Product Image:</label>
                            <input type="file" name="product_image" id="product_image" className="form-control" accept="image/*" onChange={handleChange} required />
                          </div>

                          <div className="form-group">
                            <label htmlFor="product_short_desc" className="col-form-label">Short Description:</label>
                            <textarea className="form-control" name="product_short_desc" id="product_short_desc" placeholder="Enter Short Description" value={formData.product_short_desc} onChange={handleChange} required />
                          </div>
                          <div className="form-group">
                            <label htmlFor="product_long_desc" className="col-form-label">Long Description:</label>
                            <ReactQuill
                              value={formData.product_long_desc}
                              onChange={(value) => setFormData({ ...formData, product_long_desc: value })}
                              placeholder="Enter Long Description"
                              style={{ minHeight: '200px' }} // Set minimum height here
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="product_title" className="col-form-label">Product Source Link:</label>
                            <input type="text" className="form-control" name="product_source_link" id="product_source_link" placeholder="Product source link" value={formData.product_source_link} onChange={handleChange} required />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="submit" className="btn btn-success">Add Product Details</button>
                        </div>
                      </form>
                  </div>
                </div>
              </div>



            </div>
          </div>{/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

                {/* /.card */}

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Product Details</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table id="example1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Brand</th>
                          <th>Category</th>
                          <th>Title</th>
                          <th>Image</th>
                          <th>Short Description</th>
                          <th>Long Description</th>
                          <th>Source Link</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                          {products.map(product_detail => {
                            let brand_ttl = null; // Declare brand_ttl outside the JSX block
                            return (
                                <tr key={product_detail.id}>
                                {brands.map(brand => {
                                    if (product_detail.product_brand_id === brand.id) {
                                    brand_ttl = brand.brand_title; // Update brand_ttl value if condition is met
                                    }
                                    return null; // Return null since we don't render anything directly in the map function
                                })}
                                <td>{brand_ttl}</td> {/* Use brand_ttl outside of the loop */}
                                <td>{product_detail.product_cat_name}</td> {/* Use brand_ttl outside of the loop */}
                                <td><img src={`https://fancyltdserver.fancybeauty.com.bd/productImages/${product_detail.product_image}`} alt="Image" style={{ width: '150px', height: '150px'}}/></td>
                                <td>{product_detail.product_short_desc}</td>
                                <td dangerouslySetInnerHTML={{ __html: product_detail.product_long_desc }}></td>
                                <td>{product_detail.product_source_link}</td>
                                <td>
                                    <button className="btn btn-success m-2">Edit</button>
                                    <button className="btn btn-danger m-2" onClick={() => handleDelete(product_detail.id)}>Delete</button>
                                </td>
                                </tr>
                            );
                            })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Brand</th>
                          <th>Category</th>
                          <th>Title</th>
                          <th>Image</th>
                          <th>Short Description</th>
                          <th>Long Description</th>
                          <th>Source Link</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        </>
  
        
    </div>

  );
};

export default ProductPage;
