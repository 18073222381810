import React from 'react';
import {Link} from 'react-router-dom';
const Dashboard = () => {
  return (
    <div>
        <h1>Dashboard</h1>
    </div>
  );
};

export default Dashboard;