import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import PullToRefresh from 'react-pull-to-refresh';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const GroupPage = () => {


  const [group_details, setDatas] = useState([]);
  const [formData, setFormData] = useState({ group_title: '', group_short_desc: '', group_long_desc: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async () => {

    setLoading(true);
    try {
      const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/group');
      setDatas(response.data);
    } catch (error) {
      console.error('Error fetching: ', error);
    } finally {
      setLoading(false);
    }

  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post('https://fancyltdserver.fancybeauty.com.bd/sys/api/group', formData);
      fetchDatas();
      setFormData({ c_name: '', c_email: '', c_phone: '', c_address: '', c_logo: '' });
      console.log(response.data.message);
      toast.success(response.data.message);
    } catch (error) {
      console.error('Error creating or updating: ', error);
      toast.error('Error creating or updating. Please try again.');
    }
  };

  const handleDelete = async id => {
    try {
      await axios.delete(`https://fancyltdserver.fancybeauty.com.bd/sys/api/group/${id}`);
      fetchDatas();
      toast.success('Deleted successfully');
    } catch (error) {
      console.error('Error deleting: ', error);
    }
  };


  const refreshHandler = async () => {
    await fetchDatas();
    return true; // Return true to indicate that the refresh has completed
  };

  return (
    <div>
        <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">

              <div className="col-sm-6">
                <h1>Group Details - <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Add Group</button></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item active">Group Details</li>
                </ol>
              </div>
           

              {/* <!-- Modal --> */}
              <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Add</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                    <div className="modal-body">
                      
                        <div className="form-group">
                          <label for="recipient-name" className="col-form-label">Title:</label>
                          <input type="text" className="form-control" name="group_title" value={formData.c_name} placeholder="Group Title" onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                          <label for="short-description" className="col-form-label">Short Description:</label>
                          <textarea className="form-control" name="group_short_desc" placeholder="Enter Short Description" onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                          <label htmlFor="short-description" className="col-form-label">Long Description:</label>
                          <ReactQuill
                            value={formData.group_long_desc}
                            onChange={(value) => setFormData({ ...formData, group_long_desc: value })}
                            placeholder="Enter Long Description"
                            style={{ minHeight: '200px' }} // Set minimum height here
                          />
                        </div>

                      
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-success">Add Group Details</button>
                    </div>
                    </form>
                  </div>
                </div>
              </div>



            </div>
          </div>{/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

                {/* /.card */}

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Group Details</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table id="example1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Short Description</th>
                          <th>Long Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {group_details.map(group_detail => (
                        <tr key={group_detail.id}>
                          <td>{group_detail.group_title}</td>
                          <td>{group_detail.group_short_desc}</td>
                          <td dangerouslySetInnerHTML={{ __html: group_detail.group_long_desc }}></td>
                          <td>
                            <button className="btn btn-success m-2">Edit</button>
                            <button className="btn btn-danger m-2" onClick={() => handleDelete(group_detail.id)}>Delete</button>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Title</th>
                          <th>Short Description</th>
                          <th>Long Description</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        </>
  
        
    </div>

  );
};

export default GroupPage;