import React from 'react';

const TeamPage = () => {
  return (
    <div>
          <h1>TeamPage</h1>
    </div>
  );
};

export default TeamPage;