import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import PullToRefresh from 'react-pull-to-refresh';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BrandsPage = () => {

  const [brand_details, setDatas] = useState([]);
  const [formData, setFormData] = useState({ brand_title: '', brand_short_desc: '', brand_long_desc: '', brand_image:'' });
  const [brand_image, setBrandImage] = useState(null);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://fancyltdserver.fancybeauty.com.bd/sys/api/brand');
      setDatas(response.data);
    } catch (error) {
      console.error('Error fetching: ', error);
    } finally {
      setLoading(false);
    }

  };

  const handleChange = e => {
    if (e.target.name === 'brand_image') {
      setBrandImage(e.target.files[0]); // Update the file state
    } else {
      // Update the formData state for non-file inputs
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
  
    const formNewData = new FormData();
    formNewData.append("brand_image", brand_image); // Append logo from state
    formNewData.append("brand_title", formData.brand_title);
    formNewData.append("brand_short_desc", formData.brand_short_desc);
    formNewData.append("brand_long_desc", formData.brand_long_desc);
  
     console.log(formNewData);
  
    try {
      // const response = await axios.post('https://fancyltdserver.fancybeauty.com.bd/sys/api/brand', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // });
      const response = await axios.post('https://fancyltdserver.fancybeauty.com.bd/sys/api/brand', formNewData);
      fetchDatas();
      setFormData({ brand_title: '', brand_short_desc: '', brand_long_desc: '', brand_image: '' });
      console.log(response.data.message);
      toast.success(response.data.message);
    } catch (error) {
      console.error('Error creating or updating: ', error);
      toast.error('Error creating or updating. Please try again.');
    }
  };
  

  const handleDelete = async id => {
    try {
      await axios.delete(`https://fancyltdserver.fancybeauty.com.bd/sys/api/brand/${id}`);
      fetchDatas();
      toast.success('Deleted successfully');
    } catch (error) {
      console.error('Error deleting: ', error);
    }
  };


  const refreshHandler = async () => {
    await fetchDatas();
    return true; // Return true to indicate that the refresh has completed
  };

  return (
    <div>
        <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">

              <div className="col-sm-6">
                <h1>Brand Details - <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Add Brand</button></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item active">Brand Details</li>
                </ol>
              </div>
           

              {/* <!-- Modal --> */}
              <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Add</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                          <div className="form-group">
                            <label htmlFor="brand_title" className="col-form-label">Name:</label>
                            <input type="text" className="form-control" name="brand_title" id="brand_title" placeholder="Brand Name" value={formData.brand_title} onChange={handleChange} required />
                          </div>
                          <div className="form-group">
                            <label htmlFor="brand_image" className="col-form-label">Brand Image:</label>
                            <input type="file" name="brand_image" id="brand_image" className="form-control" accept="image/*" onChange={handleChange} required />
                          </div>

                          <div className="form-group">
                            <label htmlFor="brand_short_desc" className="col-form-label">Short Description:</label>
                            <textarea className="form-control" name="brand_short_desc" id="brand_short_desc" placeholder="Enter Short Description" value={formData.brand_short_desc} onChange={handleChange} required />
                          </div>
                          <div className="form-group">
                            <label htmlFor="brand_long_desc" className="col-form-label">Long Description:</label>
                            <ReactQuill
                              value={formData.brand_long_desc}
                              onChange={(value) => setFormData({ ...formData, brand_long_desc: value })}
                              placeholder="Enter Long Description"
                              style={{ minHeight: '200px' }} // Set minimum height here
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="submit" className="btn btn-success">Add Brand Details</button>
                        </div>
                      </form>
                  </div>
                </div>
              </div>



            </div>
          </div>{/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">

                {/* /.card */}

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Brand Details</h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table id="example1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Image</th>
                          <th>Short Description</th>
                          <th>Long Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {brand_details.map(brand_detail => (
                        <tr key={brand_detail.id}>
                          <td>{brand_detail.brand_title}</td>
                          <td><img src={`https://fancyltdserver.fancybeauty.com.bd/brandImages/${brand_detail.brand_image}`}  alt="Image" style={{ width: '150px', height: '150px'}} /></td>
                          <td>{brand_detail.brand_short_desc}</td>
                          <td dangerouslySetInnerHTML={{ __html: brand_detail.brand_long_desc }}></td>
                          <td>
                            <button className="btn btn-success m-2">Edit</button>
                            <button className="btn btn-danger m-2" onClick={() => handleDelete(brand_detail.id)}>Delete</button>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Title</th>
                          <th>Image</th>
                          <th>Short Description</th>
                          <th>Long Description</th>
                          <th>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        </>
  
        
    </div>

  );
};

export default BrandsPage;
